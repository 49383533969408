import { LocalDate } from "js-joda";
import {
  ComplianceCaregiverRecord,
  ComplianceDocument,
  DashboardComplianceItem,
} from "../../../../models/compliance";
import { assertNever } from "../../../../scripts/consts/assertNever.const";
import { caregiverStatus } from "../../../../scripts/consts/commonConsts";
import { dateUtils } from "../../../../scripts/consts/dateUtils";
import {
  CaregiverDict,
  CaregiverStatus,
  CaregiverWithDisplayName,
} from "../../../../scripts/messages/caregiver";
import { ComplianceStatsResponse } from "../../../../scripts/messages/compliance";
import { AgencyId, CaregiverDocumentTypeId, CaregiverId } from "../../../../scripts/messages/ids";
import { CompService } from "../../comp.service";
import { DatabaseApiService } from "../../../../scripts/services/db";
import { FilterManager } from "../../../../scripts/services/filterManagerService";
import { getCaregiverStatusColor, LabelColor } from "../../../../scripts/utils/caregiverUtils";
import {
  getCaregiverOverviewComplianceStatusColor,
  getComplianceItemOverviewStatusColor,
  parseSectionLabel,
  sumItemsStatusGroups,
} from "../../compliance.utils";
import { assertDefined, groupBy, upperCaseFirst } from "../../../../scripts/utils/generalUtils";
import "./compliance-dashboard.component.scss";
import { MultiselectCallbackParameter } from "../../../shared/components/multiselect/multiselect.component";
import {
  PieChartEntry,
  PieChartColor,
  ChartLabel,
} from "../../../shared/components/pie-chart/pie-chart.component";

type ChartName = "employmentStatus" | "complianceStatus" | "itemsComplianceStatus";

type CardName =
  | "incompliantCaregiversWithActiveCase"
  | "caregiversWithOnlyOneIncompliantItem"
  | "incompliantItemsWithUploadedDocuments"
  | "pendingApplicationCaregivers";

type CaregiverComplianceStatus = "Compliant" | "Not compliant";
type IsMedflytCG = "Yes" | "No" ;

interface CaregiverTableEntry extends CaregiverWithDisplayName {
  complianceStatus: CaregiverComplianceStatus;
  complianceStatusColor: "red" | "green";
}

interface ComplianceItemTableEntry extends DashboardComplianceItem {
  display: {
    text: string;
    color: LabelColor;
  };
}

interface TableEntry {
  caregiver: CaregiverTableEntry;
  items: Record<string, ComplianceItemTableEntry>;
  isChecked: boolean;
}

interface TableColumn {
  title: string;
  section: string;
  colored: boolean;
  sort?: "asc" | "desc";
  show: boolean;
  caregiverDocumentTypeId: CaregiverDocumentTypeId | null;
}

interface TableColumnGroup {
  section: string;
  columns: TableColumn[];
  count: number;
  show: boolean;
}

type FilterLabelKey =
  | CardName
  | ChartName
  | "caregiverStatus"
  | "caregiverComplianceStatus"
  | "freeText"
  | "IsMedflytCG";

interface FilterLabel {
  key: FilterLabelKey;
  title: string;
  values: string[];
  onDismiss?: () => void;
}

interface MissingItems {
  required: ComplianceDocument[];
  notRequired: ComplianceDocument[];
}

//! @ngInject
class ComplianceDashboardCtrl implements ng.IComponentController {
  items?: ComplianceCaregiverRecord[];
  missingFollowupItems?: Map<CaregiverId, Set<CaregiverDocumentTypeId>>;
  charts: Record<ChartName, PieChartEntry[]> = {
    employmentStatus: [],
    complianceStatus: [],
    itemsComplianceStatus: [],
  };
  stats?: ComplianceStatsResponse;
  localStats: {
    pendingApplicationCaregivers: CaregiverId[];
  } = {
    pendingApplicationCaregivers: [],
  };
  table: NgTableParams<TableEntry>;
  dropdownDocumentOptions: { id: CaregiverDocumentTypeId; label: string }[] = [];
  tableDocumentsColumns: TableColumn[] = [
    {
      title: "Caregiver Name",
      section: "Caregiver Information",
      colored: false,
      show: true,
      caregiverDocumentTypeId: null,
    },
    {
      title: "ID",
      section: "Caregiver Information",
      colored: false,
      show: true,
      caregiverDocumentTypeId: null,
    },
    {
      title: "Compliance",
      section: "Caregiver Information",
      colored: false,
      show: true,
      caregiverDocumentTypeId: null,
    },
  ];
  tableDocumentsColumnsGroups: TableColumnGroup[] = [];
  rootDocumentsRecords: Record<string, ComplianceDocument> = {};
  documentTypes?: ComplianceDocument[];
  documentTypesMap?: Record<string, ComplianceDocument>;

  private selectedDocumentTypeIds: Set<CaregiverDocumentTypeId> = new Set();
  private tableDataset?: TableEntry[];
  private caregiversMap: CaregiverDict = {};

  public isLoadingItems = true;
  public isLoadingStats = true;
  public isLoadingCaregivers = true;
  public isOnboardingAgency = false;
  public filters = {
    freeText: "",
    dropdowns: {
      caregiverStatus: {
        options: caregiverStatus.dropdown,
        selected: [{ id: "ACTIVE", label: "ACTIVE" }],
      },
      complianceStatus: {
        options: ["Compliant", "Not compliant"].map((status) => ({ id: status, label: status })),
        selected: [],
      },
      medflytCG: {
        options: ["Yes", "No"].map((status) => ({ id: status, label: status })),
        selected: [],
      },
    },
  };
  public filterLabels: Record<FilterLabelKey, FilterLabel> = {
    incompliantCaregiversWithActiveCase: {
      key: "incompliantCaregiversWithActiveCase",
      title: "Not compliant caregivers are active on a case",
      values: [],
    },
    caregiversWithOnlyOneIncompliantItem: {
      key: "caregiversWithOnlyOneIncompliantItem",
      title: "Are missing only 1 item to be compliant",
      values: [],
    },
    incompliantItemsWithUploadedDocuments: {
      key: "incompliantItemsWithUploadedDocuments",
      title: "Non-compliant items that are available on the passport",
      values: [],
    },
    pendingApplicationCaregivers: {
      key: "pendingApplicationCaregivers",
      title: "Caregivers in pending application",
      values: [],
    },
    employmentStatus: {
      key: "employmentStatus",
      title: "Agency employment status",
      values: [],
      onDismiss: () => (this.charts.employmentStatus = [...this.charts.employmentStatus]),
    },
    complianceStatus: {
      key: "complianceStatus",
      title: "Agency compliance status",
      values: [],
      onDismiss: () => (this.charts.complianceStatus = [...this.charts.complianceStatus]),
    },
    itemsComplianceStatus: {
      key: "itemsComplianceStatus",
      title: "Compliance status per item",
      values: [],
      onDismiss: () => (this.charts.itemsComplianceStatus = [...this.charts.itemsComplianceStatus]),
    },
    caregiverStatus: {
      key: "caregiverStatus",
      title: "Caregiver status",
      values: [],
      onDismiss: () => (this.filters.dropdowns.caregiverStatus.selected = []),
    },
    caregiverComplianceStatus: {
      key: "caregiverComplianceStatus",
      title: "Caregiver compliance status",
      values: [],
      onDismiss: () => (this.filters.dropdowns.complianceStatus.selected = []),
    },
    IsMedflytCG: {
      key: "IsMedflytCG",
      title: "Is Medflyt CG",
      values: [],
      onDismiss: () => (this.filters.dropdowns.medflytCG.selected = []),
    },
    freeText: {
      key: "freeText",
      title: "Search",
      values: [],
      onDismiss: () => (this.filters.freeText = ""),
    },
  };
  public activeFilters: FilterLabel[] = [];
  public filterManager: FilterManager<TableEntry, FilterLabel>;
  public cardsFromServerData: Partial<Record<CardName, { icon: string; title: string }>> = {
    incompliantCaregiversWithActiveCase: {
      icon: "fa-calendar",
      title: this.filterLabels.incompliantCaregiversWithActiveCase.title,
    },
    caregiversWithOnlyOneIncompliantItem: {
      icon: "fa-bolt",
      title: this.filterLabels.caregiversWithOnlyOneIncompliantItem.title,
    },
    incompliantItemsWithUploadedDocuments: {
      icon: "fa-id-card",
      title: this.filterLabels.incompliantItemsWithUploadedDocuments.title,
    },
  };
  public selectedItems: Set<TableEntry> = new Set();

  constructor(
    private compService: CompService,
    private toaster: toaster.IToasterService,
    private NgTableParams: NgTable.ITableParamsConstructor<TableEntry>,
    private $rootScope: ng.IRootScopeService,
    private $scope: ng.IScope,
    private DatabaseApi: DatabaseApiService,
    private mfModal: any
  ) {
    this.table = new this.NgTableParams({}, { dataset: [] });
    this.filterManager = new FilterManager<TableEntry, FilterLabel>([]);
    this.isOnboardingAgency = this.$rootScope.agencyId === AgencyId.wrap(6205);
    this.fetchCaregivers();
  }

  $onInit = () => {
    this.getStats();
    this.$rootScope.$on("got_caregivers_data", () => this.fetchCaregivers());
  };

  onItemsFetched = () => {
    if (
      this.items === undefined ||
      this.missingFollowupItems === undefined ||
      this.documentTypes === undefined
    ) {
      return;
    }

    const caregiverMissingItemsMap: Map<CaregiverId, MissingItems> = new Map();
    const caregiverItemsMap: Map<CaregiverId, DashboardComplianceItem[]> = new Map();

    for (const record of this.items) {
      caregiverItemsMap.set(record.caregiver.id, record.items);
    }

    this.items = Object.keys(this.caregiversMap).map((caregiverIdString) => {
      const caregiver = this.caregiversMap[caregiverIdString] as CaregiverWithDisplayName;
      const caregiverId = Number(caregiverIdString) as unknown as CaregiverId;
      const items = caregiverItemsMap.get(caregiverId) ?? [];

      caregiverMissingItemsMap.set(caregiverId, this.getCaregiverMissingItems(caregiver, items));

      return {
        caregiver: this.caregiversMap[caregiverIdString],
        items: items,
      };
    });

    this.$rootScope.$emit("got_compliance_data", {
      records: this.items,
      documentTypes: this.documentTypes,
      caregiverMissingItemsMap: caregiverMissingItemsMap,
    });

    this.initTableWithItems(this.items);
  };

  getItems = () => {
    this.compService
      .fetch(this.caregiversMap)
      .then((data) => {
        this.setDocumentTypes(data.documents);
        this.dropdownDocumentOptions = data.documents.map((document) => ({
          id: document.id,
          label: document.name,
        }));
        this.rootDocumentsRecords = Object.fromEntries(
          data.documents.flatMap((document) => (document.isRoot ? [[document.name, document]] : []))
        );
        this.tableDocumentsColumns = this.colorColumns([
          ...this.tableDocumentsColumns,
          ...Object.values(this.rootDocumentsRecords)
            .map((document) => ({
              title: document.name,
              section: parseSectionLabel(document.section),
              colored: false,
              show: true,
              caregiverDocumentTypeId: document.id,
            }))
            .sort(this.sortSections),
        ]);
        this.tableDocumentsColumnsGroups = [...groupBy(this.tableDocumentsColumns, "section")].map(
          ([section, columns]) => ({
            section,
            columns,
            count: section === "Caregiver Information" ? columns.length + 1 : columns.length,
            show: true,
          })
        );
        this.items = data.items;
        this.onItemsFetched();
      })
      .catch((err) => {
        console.error(err);
        this.toaster.error("Could not load compliance items");
      })
      .finally(() => {
        this.isLoadingItems = false;
      });
  };

  getStats = () => {
    this.compService
      .fetchStats()
      .then((data) => {
        this.stats = data;
        this.setCharts();
      })
      .catch((err) => {
        console.error(err);
        this.toaster.error("Could not load compliance stats");
      })
      .finally(() => {
        this.isLoadingStats = false;
      });
  };

  setCharts = () => {
    if (this.stats === undefined) {
      return;
    }

    this.charts.employmentStatus = this.stats.employment.map(({ status, caregiverIds }) => ({
      label: upperCaseFirst(status),
      value: caregiverIds.length,
      color:
        status === "Future status of on hold"
          ? PieChartColor.yellow
          : this.parseColor(getCaregiverStatusColor(status)),
    }));

    this.charts.complianceStatus = this.stats.caregivers.map(({ status, caregiverIds }) => ({
      label: upperCaseFirst(status),
      value: caregiverIds.length,
      color: getCaregiverOverviewComplianceStatusColor(status),
    }));

    this.initItemsComplianceStatusChartEntries();
  };

  setLocalStats = () => {
    this.localStats.pendingApplicationCaregivers = Object.values(this.caregiversMap)
      .filter((caregiver) => caregiver.status === "PENDING")
      .map((caregiver) => caregiver.id);
  };

  openCaregiverModal = (id: CaregiverId) =>
    this.$rootScope.openCaregiverModal(
      id,
      assertDefined(this.caregiversMap[id as unknown as number], "caregiver")
    );

  onDocumentSelect = (records: MultiselectCallbackParameter<CaregiverDocumentTypeId>) => {
    this.selectedDocumentTypeIds = new Set(records.map((r) => r.id));
    this.initItemsComplianceStatusChartEntries();
    this.onChartFilter("itemsComplianceStatus", [], false);
    this.toggleTableColumns();
  };

  onCardClick = (card: CardName) => {
    const shouldFilter = !this.filterManager.hasFilter(this.filterLabels[card]);

    this.onCardFilter(card, shouldFilter);
  };

  onRowClick = (row: TableEntry) => {
    row.isChecked = !row.isChecked;

    this.onCheckRow(row);
  };

  onCaregiverStatusSelect = (selected: MultiselectCallbackParameter<CaregiverStatus>) => {
    if (selected.length === 0) {
      this.removeFilter("caregiverStatus");
      return;
    }

    const statuses = new Set(selected.map((r) => r.id));

    this.filter(
      "caregiverStatus",
      (record) => statuses.has(record.caregiver.status),
      Array.from(statuses)
    );
  };

  onComplianceStatusSelect = (
    selected: MultiselectCallbackParameter<CaregiverComplianceStatus>
  ) => {
    if (selected.length === 0) {
      this.removeFilter("caregiverComplianceStatus");
      return;
    }

    const statuses = new Set(selected.map((r) => r.id));

    this.filter(
      "caregiverComplianceStatus",
      (record) => statuses.has(record.caregiver.isCompliant ? "Compliant" : "Not compliant"),
      Array.from(statuses)
    );
  };

  onMedflytCGStatusSelect = (
    selected: MultiselectCallbackParameter<IsMedflytCG>
  ) => {
    if (selected.length === 0) {
      this.removeFilter("caregiverComplianceStatus");
      return;
    }

    const statuses = new Set(selected.map((r) => r.id));

    this.filter(
      "caregiverComplianceStatus",
      (record) => statuses.has(record.caregiver.isFromLandingPage ? "Yes" : "No"),
      Array.from(statuses)
    );
  };

  onSearchChange = () => {
    const freeText = this.filters.freeText.trim();

    if (freeText === "") {
      this.removeFilter("freeText");
      return;
    }

    const regexp = new RegExp(freeText, "i");

    this.filter(
      "freeText",
      (item) => {
        return (
          item.caregiver.displayId === Number(freeText) || regexp.test(item.caregiver.displayName)
        );
      },
      [freeText]
    );
  };

  onClickSort = (columnTitle: string) => {
    const column = this.tableDocumentsColumns.find((c) => c.title === columnTitle);

    if (column === undefined) {
      console.error("Could not find column", columnTitle);
      return;
    }

    const sort = column.sort === "asc" ? "desc" : "asc";

    this.sortTable(column, sort);
  };

  onChartFilter = (chartName: ChartName, labels: ChartLabel[], needsDigest?: boolean) => {
    if (labels.every((label) => !label.isHidden)) {
      this.removeFilter(chartName);
      if (needsDigest !== false) {
        this.$scope.$digest();
      }
      return;
    }

    let callback: (record: TableEntry) => boolean;

    const activeLabels = labels.filter((label) => !label.isHidden);

    switch (chartName) {
      case "employmentStatus": {
        const activeStatuses = new Set(
          activeLabels.map((label) =>
            label.label === "Future status of on hold" ? label.label : label.label.toUpperCase()
          )
        );
        const caregiverIds = new Set(
          this.stats?.employment
            .filter((item) => activeStatuses.has(item.status))
            .map((item) => item.caregiverIds)
            .flat() ?? []
        );
        callback = (record) => caregiverIds.has(record.caregiver.id);
        break;
      }

      case "complianceStatus": {
        const activeStatuses = new Set(activeLabels.map((label) => label.label));
        const caregiverIds = new Set(
          this.stats?.caregivers
            .filter((item) => activeStatuses.has(item.status))
            .map((item) => item.caregiverIds)
            .flat() ?? []
        );
        callback = (record) => caregiverIds.has(record.caregiver.id);
        break;
      }

      case "itemsComplianceStatus": {
        const activeStatuses = new Set(activeLabels.map((label) => label.label));
        const caregiverIds = new Set(
          this.stats?.items
            .filter(
              (item) =>
                (this.selectedDocumentTypeIds.size === 0 ||
                  this.selectedDocumentTypeIds.has(item.documentTypeId)) &&
                activeStatuses.has(item.status)
            )
            .map((item) => item.caregiverIds)
            .flat() ?? []
        );
        callback = (record) => caregiverIds.has(record.caregiver.id);
        break;
      }

      default:
        assertNever(chartName);
    }

    this.filter(
      chartName,
      callback,
      activeLabels.map((label) => label.label)
    );

    if (needsDigest !== false) {
      this.$scope.$digest();
    }
  };

  onCardFilter = (cardName: CardName, isActive: boolean) => {
    if (!isActive) {
      this.removeFilter(cardName);
      return;
    }

    let caregiverIds: Set<CaregiverId>;

    switch (cardName) {
      case "pendingApplicationCaregivers":
        caregiverIds = new Set(
          Object.values(this.caregiversMap)
            .filter((caregiver) => caregiver.status === "PENDING")
            .map((caregiver) => caregiver.id)
        );
        break;

      case "incompliantCaregiversWithActiveCase":
        caregiverIds = new Set(this.stats?.incompliantCaregiversWithActiveCase ?? []);
        break;

      case "caregiversWithOnlyOneIncompliantItem":
        caregiverIds = new Set(this.stats?.caregiversWithOnlyOneIncompliantItem ?? []);
        break;

      case "incompliantItemsWithUploadedDocuments":
        caregiverIds = new Set(
          this.stats?.incompliantItemsWithUploadedDocuments.caregiverIds ?? []
        );
        break;

      default:
        assertNever(cardName);
    }

    this.filter(cardName, (record) => caregiverIds.has(record.caregiver.id));
  };

  onCheckRow = (item: TableEntry) => {
    if (item.isChecked) {
      this.selectedItems.add(item);
    } else {
      this.selectedItems.delete(item);
    }
  };

  onClickSendReminders = () => {
    const caregivers = Array.from(this.selectedItems).map((item) => item.caregiver);

    const modal = this.mfModal.create({
      subject: "Send compliance reminders",
      message:
        "Using this feature will send reminders to the selected caregivers through medflyt's app chat.",
      cancelLabel: "Cancel",
      confirmLabel: "Send reminders",
      showInput: true,
      inputPlaceholder:
        "Write something like: Hi, your flu shot is about to expire. Please submit a new one.",
      inputIsRequired: true,
      inputType: "textarea",
      layoutOrder: ["message", "customTemplate", "input"],
      hideCancelButton: false,
      preventBackdropClose: true,
      customTemplate: "reminders-modal-content",
      data: {
        selectedCaregivers: caregivers,
      },
      onConfirm: ({ inputModel }) => {
        const confirmationModal = this.mfModal.create({
          subject: `${this.selectedItems.size} Caregivers will receive the following message:`,
          message: inputModel,
          cancelLabel: "Cancel",
          confirmLabel: "Confirm",
          preventBackdropClose: true,
          onConfirm: () => {
            confirmationModal.setLoading(true);
            this.compService
              .sendReminders(
                caregivers.map((caregiver) => caregiver.id),
                inputModel
              )
              .then(() => {
                this.toaster.success("Reminders sent successfully");
                modal.close();
              })
              .catch(() => {
                this.toaster.error("Failed to send reminders");
              })
              .finally(() => {
                confirmationModal.close();
              });
          },
          onComplete: () => {
            confirmationModal.close();
          },
        });
      },
    });
  };

  private initTableWithItems = (items: ComplianceCaregiverRecord[]) => {
    this.tableDataset = items.map(this.parseTableData);
    this.filterManager = new FilterManager(this.tableDataset, this.initTable);
    this.onCaregiverStatusSelect([{ id: "ACTIVE" }]);
    this.initTable();
  };

  private initTable = (dataset?: TableEntry[]) => {
    if (dataset !== undefined) {
      this.tableDataset = dataset;
    }

    this.table = new this.NgTableParams(
      { count: this.table.count() },
      { dataset: this.tableDataset }
    );
  };

  private setDocumentTypes = (types: ComplianceDocument[]) => {
    this.documentTypes = types;

    this.documentTypesMap = Object.fromEntries(
      types.map((type) => [
        type.name,
        type,
      ]),
    );
  };

  private getMissingFollowupItems = () => {
    this.compService
      .fetchMissingFollowupItems()
      .then((data) => {
        const map: Map<CaregiverId, Set<CaregiverDocumentTypeId>> = new Map();

        for (const item of data) {
          const { caregiverId, documentTypeId } = item;

          if (!map.has(caregiverId)) {
            map.set(caregiverId, new Set());
          }

          map.get(caregiverId)!.add(documentTypeId);
        }

        this.missingFollowupItems = map;
        this.onItemsFetched();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  private getCaregiverMissingItems = (caregiver: CaregiverWithDisplayName, items: DashboardComplianceItem[]) => {
    const set = new Set();
    const missingItems: MissingItems = {
      required: [],
      notRequired: [],
    };

    items.forEach((item) => set.add(item.documentType.id));

    this.documentTypes!.forEach((documentType) => {
      if (set.has(documentType.id)) {
        return;
      }

      if (documentType.followupParentId !== null && !set.has(documentType.followupParentId)) {
        return;
      }

      if (!documentType.isRoot) {
        return;
      }

      if (!documentType.certifications.some((cert) => caregiver.certifications.includes(cert))) {
        return;
      }

      const key = this.isRequiredDocument(caregiver.id, documentType) ? "required" : "notRequired";

      missingItems[key].push(documentType);
    });

    return missingItems;
  };

  private onCaregiversLoaded = () => {
    this.isLoadingCaregivers = false;
    this.getItems();
    this.getMissingFollowupItems();
    this.setLocalStats();
  };

  private fetchCaregivers = () => {
    if (Object.keys(this.caregiversMap).length !== 0) {
      return;
    }

    this.caregiversMap = this.DatabaseApi.caregivers();

    if (Object.keys(this.caregiversMap).length !== 0) {
      this.onCaregiversLoaded();
    }
  };

  public getDisplayText = (title: string, entry: TableEntry) => {
    if (this.documentTypesMap === undefined) {
      return "";
    }

    const item = entry.items[title];
    const documentType = this.documentTypesMap[title];

    if (item?.display.text !== undefined) {
      return item.display.text;
    }

    if (!this.isRequiredDocument(entry.caregiver.id, documentType)) {
      return "Not required";
    }

    return "Missing";
  };

  public getDisplayTextColor = (title: string, entry: TableEntry) => {
    if (this.documentTypesMap === undefined) {
      return "";
    }

    const item = entry.items[title];
    const documentType = this.documentTypesMap[title];

    if (item?.display.color !== undefined) {
      return item.display.color;
    }

    if (!this.isRequiredDocument(entry.caregiver.id, documentType)) {
        return "gray";
      }

    return "red";
  };

  private isRequiredDocument = (caregiverId: CaregiverId, documentType: ComplianceDocument) => {
    return documentType.followupParentId === null ||
    (this.missingFollowupItems!.get(caregiverId)?.has(documentType.id) ?? false);
  };

  private parseTableData = (record: ComplianceCaregiverRecord): TableEntry => {
    return {
      caregiver: {
        ...record.caregiver,
        complianceStatus: record.caregiver.isCompliant ? "Compliant" : "Not compliant",
        complianceStatusColor: record.caregiver.isCompliant ? "green" : "red",
      },
      items: Object.fromEntries([
        ...record.items.map((item) => [
          item.documentType.name,
          {
            ...item,
            display: this.parseTableItemDisplayData(item),
          },
        ]),
      ]),
      isChecked: false,
    };
  };

  private getCellData = (entry: TableEntry, column: TableColumn): string => {
    switch (column.title) {
      case "Caregiver Name":
        return entry.caregiver.displayName;

      case "ID":
        return `${entry.caregiver.displayId}`;

      case "Compliance":
        return entry.caregiver.complianceStatus;

      default:
        return entry.items[column.title]?.display.text ?? "";
    }
  };

  private sortTable = (sortColumn: TableColumn, sort?: "asc" | "desc") => {
    if (this.tableDataset === undefined) {
      return;
    }

    this.tableDocumentsColumns = [...this.tableDocumentsColumns].map((column) => ({
      ...column,
      sort: column.title === sortColumn.title ? sort : undefined,
    }));

    this.tableDataset.sort(
      (a, b) =>
        this.getCellData(a, sortColumn).localeCompare(this.getCellData(b, sortColumn)) *
        (sort === "asc" ? 1 : -1)
    );

    this.initTable();
  };

  private parseTableItemDisplayData(item: DashboardComplianceItem): {
    text: string;
    color: LabelColor;
  } {
    switch (item.status) {
      case "Not required":
        return {
          text: "Not required",
          color: "gray",
        };

      case "Not due yet":
        return {
          text:
            item.dueDate === null || item.dueDate.compareTo(LocalDate.parse("3000-01-01")) === 0
              ? "Not due yet"
              : "Due in " + dateUtils.localDateToMDYString(item.dueDate),
          color:
            (item.dueDate?.compareTo(LocalDate.now().plusDays(30)) ?? 0) > 0 ? "gray" : "orange",
        };

      case "Resolved":
        return {
          text: "Resolved",
          color: "gray",
        };

      case "Missing":
        return {
          text: "Missing",
          color: this.getItemColorLabel(item),
        };

      case "Pending Uploads":
        return {
          text: "Pending Uploads",
          color: this.getItemColorLabel(item),
        };

      case "Compliant":
      case "Not Compliant":
        return {
          text: this.getDueDateText(item),
          color: this.getItemColorLabel(item),
        };

      default:
        assertNever(item.status);
    }
  }

  private getItemColorLabel(item: DashboardComplianceItem): LabelColor {
    if (item.status === "Pending Uploads") {
      return "orange";
    }

    if (item.status === "Compliant") {
      if ((item.expiryDate?.compareTo(LocalDate.now().plusDays(30)) ?? 0) < 0) {
        return "orange";
      }

      return "green";
    }

    return "red";
  }

  private getDueDateText(item: DashboardComplianceItem) {
    return [item.effectiveDate, item.expiryDate]
      .flatMap((date) =>
        date === null || date === undefined ? [] : [dateUtils.localDateToMDYString(date)]
      )
      .join(" - ");
  }

  private initItemsComplianceStatusChartEntries() {
    const items = sumItemsStatusGroups(
      groupBy(
        (this.stats?.items ?? [])
          .filter(
            (item) =>
              this.selectedDocumentTypeIds.size === 0 ||
              this.selectedDocumentTypeIds.has(item.documentTypeId)
          )
          .map(({ documentTypeId, status, caregiverIds }) => ({
            documentTypeId,
            status,
            totalCount: caregiverIds.length,
          })),
        "status"
      )
    );

    this.charts.itemsComplianceStatus = Array.from(items).map(([status, totalCount]) => ({
      label: upperCaseFirst(status),
      value: totalCount,
      color: getComplianceItemOverviewStatusColor(status),
    }));
  }

  private parseColor(color: LabelColor): PieChartColor {
    switch (color) {
      case "red":
        return PieChartColor.red;

      case "green":
        return PieChartColor.green;

      case "orange":
        return PieChartColor.orange;

      case "gray":
        return PieChartColor.gray;

      case "lightblue":
        return PieChartColor.blue;
    }
  }

  private colorColumns(columns: TableColumn[]): TableColumn[] {
    let colored = false;

    return columns.map((column, i) => {
      colored = i === 0 || columns[i - 1].section === column.section ? colored : !colored;

      return {
        ...column,
        colored,
      };
    });
  }

  private sortSections = (a: TableColumn, b: TableColumn): number => {
    const defaultSections = ["General", "Medical", "I9"];

    if (defaultSections.includes(a.section) && !defaultSections.includes(b.section)) {
      return -1;
    }

    if (!defaultSections.includes(a.section) && defaultSections.includes(b.section)) {
      return 1;
    }

    return a.section.localeCompare(b.section);
  };

  private toggleTableColumns = () => {
    this.tableDocumentsColumns.forEach((column) => {
      if (column.caregiverDocumentTypeId === null) {
        return;
      }

      column.show =
        this.selectedDocumentTypeIds.size === 0 ||
        this.selectedDocumentTypeIds.has(column.caregiverDocumentTypeId);
    });

    this.tableDocumentsColumnsGroups.forEach((group) => {
      const count = group.columns.filter((column) => column.show).length;
      group.count = group.section === "Caregiver Information" ? count + 1 : count;
      group.show = count > 0;
    });
  };

  private filter = (
    key: FilterLabelKey,
    filter: (item: TableEntry) => boolean,
    values?: string[]
  ) => {
    this.filterLabels[key].values = values ?? [];
    this.filterManager.addFilter(this.filterLabels[key], filter);
    this.activeFilters = this.filterManager.getFilters();
  };

  private removeFilter(key: FilterLabelKey) {
    const filterLabel = this.filterLabels[key];

    this.filterManager.removeFilter(filterLabel);

    this.activeFilters = this.filterManager.getFilters();

    if (filterLabel.onDismiss !== undefined) {
      filterLabel.onDismiss();
    }
  }
}

interface Component extends angular.IComponentOptions {
  $name: string;
}

export const complianceDashboardComponent: Component = {
  $name: "complianceDashboard",
  templateUrl:
    "admin/modules/compliance/components/compliance-dashboard/compliance-dashboard.component.html",
  controller: ComplianceDashboardCtrl,
  controllerAs: "ctrl",
};
