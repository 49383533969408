import { LocalTime } from "js-joda";
import { match } from "ts-pattern";
import { CaregiverRequest } from "../../visitBroadcast.types";

export type WeeklyTemplateActionEnum = "REPLACE" | "ADD" | "NO_EFFECT";

const weeklyTemplateActions = new Map<number, WeeklyTemplateActionEnum>();
weeklyTemplateActions.set(1, "REPLACE");
weeklyTemplateActions.set(2, "NO_EFFECT");
weeklyTemplateActions.set(3, "ADD");


export function convertWeeklyTemplateModalActionToEnumValue(actionNum: 1 | 2 | 3){
    if(weeklyTemplateActions.get(actionNum) === undefined){
        throw new Error("Could not get weekly template action.")
    }

    return weeklyTemplateActions.get(actionNum);
}

export function areCaregiverRequestsEqual(first: CaregiverRequest, second: CaregiverRequest){ 
    const areEquals = match({ first, second })
        .with({ first: { type: "REAL_REQUEST" }, second: { type: "REAL_REQUEST" }}, (res) => {
            const areStartTimesEqual = LocalTime.parse(res.first.startTime.toString()).equals(LocalTime.parse(res.second.startTime.toString())); 
            const areEndTimesEqual = LocalTime.parse(res.first.endTime.toString()).equals(LocalTime.parse(res.second.endTime.toString())); 

            return  res.first.day.equals(res.second.day) && 
                    res.first.caregiverId === res.second.caregiverId &&
                    areStartTimesEqual &&
                    areEndTimesEqual  
        })
        .with({first: {type: "DISABLED"}, second: {type: "DISABLED"}}, () => true)
        .otherwise(() => false);

    return areEquals;
}