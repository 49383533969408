//! @ngInject
export function fileUploaderCtrl($scope, $rootScope, $stateParams, NgTableParams, DatabaseApi, Consts/*, controlData*/, toaster, $http) {

  // // example of 'controlData':
  // var controlData = {
  //   // Required fields:
  //   previousPageUrl: "app",
  //   createDataToUpload: function() {
  //     var formData = new FormData();
  //     formData.append('file', $scope.file, $scope.file.name);
  //     formData.append('name', $rootScope.user.displayName);
  //     formData.append('agency', $rootScope.user.agency.name);
  //     return formData;
  //   },
  //   uploadPostUrl: "agencies/" + $rootScope.agencyId + "/coordinator/" + $rootScope.agencyMemberId + "/table_file_upload",
  //   historyGetUrl: "agencies/" + $rootScope.agencyId + "/table_file_upload",
  //   fileTypeName: "Excel",
  //   allowedFileTypes: [ 'xls', 'xlsx' ],

  //   // Optional fields:
  //   caption: "Upload Excel spread sheet files",
  //   subcaption: "Please make sure your Excel spread sheet contains the following information",
  //   requiredInfoFields: [ "Name", "Status" ],
  //   css: "css-class",
  // };

  var controlData = $stateParams.data;

  var stringListOfAllowedFileTypes = Array.isArray(controlData.allowedFileTypes) ? "." + controlData.allowedFileTypes.join(", ."): "";
  var fileTypeName = controlData.fileTypeName;

  $scope.stringListOfInfoFields = null;

  function initialize() {
    $scope.previousPageUrl = controlData.previousPageUrl;
    $scope.caption = controlData.caption || "Upload files of " + fileTypeName + " type";
    $scope.subcaption = controlData.subcaption || "Please make sure your file contains the following information";
    if (controlData.requiredInfoFields) {
      $scope.stringListOfInfoFields = controlData.requiredInfoFields.join(", ");
    }
    $scope.css = controlData.css;

    $scope.file = null;
    $scope.showHistory = Boolean(controlData.historyGetUrl);
    $scope.history = [];

    if ($scope.showHistory) {
      loadHistory();
    }
  }

  $scope.$watch('file', function () {
    if ($scope.file && $scope.file.name) {
      var fileType = $scope.file.name.split('.');
      fileType = fileType[fileType.length - 1];
      fileType = fileType.toLowerCase();
      if (controlData.allowedFileTypes === undefined || controlData.allowedFileTypes.includes(fileType)) {
        fileUpload();
      } else {
        toaster.pop("warning", "Unsupported file type", "Please upload a file of " + fileTypeName + " type (" + stringListOfAllowedFileTypes + ")");
        $scope.file = null;
      }
    }
  });

  function fileUpload() {
    var dataToUpload = controlData.createDataToUpload($scope);

    $http({
      url: Consts.api + controlData.uploadPostUrl,
      method: 'POST',
      data: dataToUpload,
      headers: { 'Content-Type': undefined }
    }).then(function (response) {
      $scope.file = null;
      $scope.fileSent = 'Thanks for uploading, the file was uploaded successfully.';
      toaster.pop('success', "Success", "File Uploaded");
      loadHistory();
    }, function (error) {
      $scope.file = null;
      const errorMessage = error?.data?.error ?? error?.data?.split('\n').filter((line) => line.startsWith('Error:'))?.[0].slice(7) ?? '';
      $scope.fileSent = 'An Error Occurred.' + (errorMessage ? '\n\n' + errorMessage : '');
      toaster.pop('error', "Failed to send the file", 'Please try again');
      loadHistory();
    });
  }

  function loadHistory() {
    if (!$scope.showHistory) return;

    DatabaseApi.get(controlData.historyGetUrl).then(function (response) {
      var history = [];
      response.data.list.forEach(function(line){
        if(line.type === 'SCHEDULE') history.push(line);
      })
      $scope.history = history;

    }, function (error) {
      //
    });
  }

  initialize();

};
