export const patientBilling = {
    templateUrl: "admin/views/patient-billing.html",
    bindings: {
        patientId: '<'
    },
    //! @ngInject
    controller: function ($scope, $rootScope, DatabaseApi, NgTableParams, toaster, $q, $timeout, $uibModal) {
        $scope.caregviersMap = DatabaseApi.caregivers() || {};
        $scope.officesMap = DatabaseApi.offices() || {};
        $scope.serviceCodesMap = DatabaseApi.serviceCodes() || {};
        $scope.pageNum = 1;
        $scope.pageSize = 10;
        
        const getPatientInvoices = async (pageNum) => {
            const deferred = $q.defer();
            $scope.isLoading = true;

            DatabaseApi.post(`agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patients/${$scope.$ctrl.patientId}/invoices`, { pageNum, pageSize: $scope.pageSize })
            .then((res) => {
                deferred.resolve(res.data.invoices);
            }).catch((err) => {
                toaster.pop("error", "Failed to load billing data");
                console.log(err);
                deferred.reject(null);
            })
            .finally(() => {
                $timeout(() => $scope.isLoading = false);
            });

            return deferred.promise;
        }


        const initInvoicesTable = (invoices) => {
            const invoiceVisitTableOptions = {
                count: 10,
                sorting: { date: "desc" }
            };

            invoices.forEach((invoice) => {
                invoice.activeInvoiceTab = null;
                const office = $scope.officesMap.find(({ id }) => invoice.officeId === id);
                invoice.officeName = office.name;    
  
                invoice.invoiceVisitsTable = new NgTableParams(invoiceVisitTableOptions, {
                    counts: [],
                    dataset: invoice.currentInvoiceVisitDetails
                });

                invoice.currentInvoiceVisitDetails.forEach((visit) => {
                    const caregiver = $scope.caregviersMap[visit.caregiverId];
                    visit.caregiver = caregiver;

                    const serviceCode = $scope.serviceCodesMap.find(({ id }) => visit.serviceCodeId === id);
                    visit.serviceCodeName = serviceCode ? serviceCode.code : "No service code";            
                })
            });


            const invoicesTableOptions = {
                sorting: { date: "desc" }
            };

            $scope.invoicesTable = new NgTableParams(invoicesTableOptions, {
                counts: [],
                dataset: invoices
            });
        }

        

        async function loadData() {
            const invoices = await getPatientInvoices($scope.pageNum);
            if (invoices !== null) {
                $scope.invoices = invoices;
                initInvoicesTable(invoices);
            }
        }

        $scope.toggleRow = (row) => {
            if (row.activeInvoiceTab === null) {
                row.activeInvoiceTab = true;
            } else {
                row.activeInvoiceTab = null;
            }
        };

        $scope.viewExportHistory = ($event, visit) => {
            $event.stopPropagation();
    
            $uibModal
                .open({
                    templateUrl: "admin/views/billing-exported-invoices-modal.html",
                    size: "md",
                    controller: "billingExportedInvoicesModalCtrl",
                    windowClass: "billing-exported-invoices-modal-ctrl center-center",
                    resolve: {
                        visit
                    }
                });
        };

        $scope.nextPage = async () => {
            $scope.pageNum++;
            await loadData();
        }

        $scope.previousPage = async () => {
            $scope.pageNum--;
            await loadData();
        }

        this.$onInit = async () => {
            await loadData();
        }
    }
};